import { Injectable } from '@angular/core';
import { env } from '../../environments/environment';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';

@Injectable({
  providedIn: 'root'
})
export class S3Service {

  private readonly accessKeyId = env.s3Config.accessKey;
  private readonly secretAccessKey = env.s3Config.secretKey;
  private readonly region = env.s3Config.region;
  private readonly bucketName = env.s3Config.bucket;

  private s3Client!: S3Client;

  constructor() { }

  async initializeClient() {
    this.s3Client = new S3Client({
      region: this.region,
      credentials: {
        accessKeyId: this.accessKeyId,
        secretAccessKey: this.secretAccessKey
      }
    });
  }

  async generatePresignedUrl(key: string, expirationSeconds = 1800): Promise<string> {
    if(!key) return '';
    const command = new GetObjectCommand({ Bucket: this.bucketName, Key: key });
    const url = await getSignedUrl(this.s3Client, command, { expiresIn: expirationSeconds });
    return url;
  }
}
