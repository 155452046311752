import { Injectable } from '@angular/core';
import { env } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IDisplayConfig, IFormResponse } from '../store/formBuilder.models';
import { IAPIResponse } from '../../../models/base';
import { IQRAPIResponse } from '../../models/qr';
import { IFilterMasterResponse, IPublicGenericFormResponse } from '../../models/forms';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private formsAPIUrl = `${env.apiBaseUrl}/forms-service/forms`;
  private publicAPIUrl = `${env.apiBaseUrl}/forms-service/public`;
  private masterAPIUrl = `${env.apiBaseUrl}/forms-service/forms/master`;

  constructor(private http: HttpClient) {}

  getForm(id: string) {
    const url = `${this.formsAPIUrl}/load/${id}`;
    return this.http.get<IFormResponse>(url);
  }

  updateDisplayConfig(formId: string, config: IDisplayConfig) {
    const url = `${this.formsAPIUrl}/config/updatedisplayconfig`;
    return this.http.put<any>(url, { ...config, formId });
  }

  updateFormStatus(payload: { formId: string; isEnabled: boolean }) {
    const url = `${this.formsAPIUrl}/updatestatus`;
    return this.http.put<any>(url, payload);
  }

  addResponse(data: FormData, formId: string) {
    const url = `${this.formsAPIUrl}/response/${formId}/submit`;
    return this.http.post<any>(url, data);
  }

  deleteResponse(req: { formId: string; responseId: string }) {
    const url = `${this.formsAPIUrl}/response/delete`;
    return this.http.delete<IAPIResponse<{}>>(url, { body: req });
  }

  getQRpublicAPI(formId: string, responseId: string) {
    const url = `${this.publicAPIUrl}/qrcode/${formId}/${responseId}`;
    return this.http.get<IQRAPIResponse>(url);
  }

  getGenericFormPublic(formId: string) {
    const url = `${this.publicAPIUrl}/generic/${formId}`;
    return this.http.get<IPublicGenericFormResponse>(url);
  }

  submitPublicGenericFormResponse(formId: string, data: FormData) {
    const url = `${this.publicAPIUrl}/generic/${formId}/submit`;
    return this.http.post<any>(url, data);
  }

  getFilterMaster() {
    const url = `${this.masterAPIUrl}/datatypes`;
    return this.http.get<IFilterMasterResponse>(url);
  }
}
