import { EntityState, createEntityAdapter } from "@ngrx/entity";
import { IForm } from "./formBuilder.models";
import { IFormResponse, IResponseAPIResponse } from "../../models/qr";

export interface IFormResponseState extends EntityState<IFormResponse> {
  isLoading: boolean;
  error: string | null;
  errorList: { [key: string]: string };
  formInfo: IForm | null;
  loadResponses: boolean;
  selectedResponseId: string | null;
}

export const formResponseAdapter = createEntityAdapter<IFormResponse>();

export const initialFormResponseState: IFormResponseState =
  formResponseAdapter.getInitialState({
    isLoading: false,
    error: null,
    errorList: {},
    formInfo: null,
    loadResponses: false,
    selectedResponseId: null,
  });
