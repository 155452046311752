import { Injectable, inject } from "@angular/core";
import { Store } from "@ngrx/store";
import * as FormBuilderActions from "./formBuilder.actions";
import { selectFormError, selectFormErrorList, selectFormInfo, selectFormLoading, selectFormResponses } from "./formBuilder.selectors";
import { IResponsesRequest } from "../../models/qr";
import { IDisplayConfig } from "./formBuilder.models";


@Injectable({
  providedIn: 'root',
})
export class FormBuilderFacade {
  private readonly store: Store = inject(Store);

  formInfo$ = this.store.select(selectFormInfo);
  formLoading$ = this.store.select(selectFormLoading);
  formError$ = this.store.select(selectFormError);
  formErrorList$ = this.store.select(selectFormErrorList);
  formResponses$ = this.store.select(selectFormResponses);

  fetchFormInfo(formId: string): void {
    this.store.dispatch(FormBuilderActions.loadFormInfo({ id: formId }));
  }

  fetchAllResponses(formRequest: IResponsesRequest): void {
    this.store.dispatch(FormBuilderActions.loadFormResponse({ formRequest }));
  }

  addFormResponse(formData: FormData, formId: string): void {
    this.store.dispatch(
      FormBuilderActions.addFormResponse({ formData, formId })
    );
  }

  updateFormResponse(
    formData: FormData,
    formId: string,
    responseId: string
  ): void {
    this.store.dispatch(
      FormBuilderActions.updateFormResponse({ formData, formId, responseId })
    );
  }

  generateQRCode(formId: string, responseId: string): void {
    this.store.dispatch(FormBuilderActions.generateQR({ formId, responseId }));
  }

  clearErrorList(): void {
    this.store.dispatch(FormBuilderActions.clearFormErrorList());
  }

  updateDisplayConfig(formId: string, displayConfig: IDisplayConfig): void {
    this.store.dispatch(
      FormBuilderActions.updateDisplayConfig({ formId, displayConfig })
    );
  }

  deleteResponse(formId: string, responseId: string): void {
    this.store.dispatch(
      FormBuilderActions.deleteResponse({ formId, responseId })
    );
  }
}
