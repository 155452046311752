import { createAction, props } from "@ngrx/store";
import { IDisplayConfig, IFormResponse } from "./formBuilder.models";
import { IAPIErrorResponse, IAPIResponse } from "../../../models/base";
import { IResponseAPIResponse, IResponsesRequest, IResponseUpsertAPIResponse } from "../../models/qr";


export const loadFormInfo = createAction('[Form Info] Load Form Info', props<{ id: string }>());
export const loadFormInfoSuccess = createAction('[Form Info] Load Form Info Success', props<IFormResponse>());
export const loadFormInfoFailed = createAction('[Form Info] Load Form Info Failed', props<IAPIErrorResponse>());

export const loadFormResponse = createAction('[Form Info] Load Form Response', props<{formRequest: IResponsesRequest}>());
export const loadFormResponseSuccess = createAction('[Form Info] Load Form Response Success', props<IResponseAPIResponse>());
export const loadFormResponseFailed = createAction('[Form Info] Load Form Response Failed', props<IAPIErrorResponse>());

export const addFormResponse = createAction('[Form Info] Add Form Response', props<{formData: FormData, formId: string}>());
export const addFormResponseSuccess = createAction('[Form Info] Add Form Response Success', props<IResponseUpsertAPIResponse>());
export const addFormResponseFailed = createAction('[Form Info] Add Form Response Failed', props<IAPIErrorResponse>());

export const updateFormResponse = createAction('[Form Info] Update Form Response', props<{formData: FormData, formId: string, responseId: string}>());
export const updateFormResponseSuccess = createAction(
  '[Form Info] Update Form Response Success',
  props<{formId: string}>()
);
export const updateFormResponseFailed = createAction('[Form Info] Update Form Response Failed', props<IAPIErrorResponse>());

export const generateQR = createAction('[Form Info] Generate QR', props<{formId: string, responseId: string}>());
export const generateQRSuccess = createAction(
  '[Form Info] Generate QR Success',
  props<{ formId: string }>()
);
export const generateQRFailed = createAction('[Form Info] Generate QR Failed', props<IAPIErrorResponse>());

export const updateDisplayConfig = createAction('[Form Info] Update Display Config', props<{formId: string, displayConfig: IDisplayConfig}>());
export const updateDisplayConfigSuccess = createAction('[Form Info] Update Display Config Success', props<{formId: string}>());
export const updateDisplayConfigFailed = createAction('[Form Info] Update Display Config Failed', props<IAPIErrorResponse>());

export const deleteResponse = createAction('[Form Info] Delete Response', props<{formId: string, responseId: string}>());
export const deleteResponseSuccess = createAction('[Form Info] Delete Response Success', props<{formId: string, responseId: string}>());
export const deleteResponseFailed = createAction('[Form Info] Delete Response Failed', props<IAPIErrorResponse>());

export const clearFormErrorList = createAction('[Form Info] Clear Form Error');
