import { ActionReducerMap, createFeatureSelector, createSelector } from "@ngrx/store";
import { formResponseAdapter, IFormResponseState } from "./formBuilder.state";

const { selectIds, selectEntities, selectAll, selectTotal  } =
formResponseAdapter.getSelectors();

export const selectFormResponseState = createFeatureSelector<IFormResponseState>('formResponseState');

export const selectFormError = createSelector(
  selectFormResponseState,
  (state) => state.error
);

export const selectFormErrorList = createSelector(
  selectFormResponseState,
  (state) => state.errorList
);

export const selectFormInfo = createSelector(
  selectFormResponseState,
  (state) => state.formInfo
);
export const selectFormLoading = createSelector(
  selectFormResponseState,
  (state) => state.isLoading
);

export const selectResponseIds = selectIds;

// select the dictionary of response entities
export const selectResponseEntities = selectEntities;

// select the array of responses
// export const selectFormResponses = selectAll;

// select the total response count
export const selectTotalResponses = selectTotal;


export const selectFormResponses = createSelector(
  selectFormResponseState,
  selectAll
);
