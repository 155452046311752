import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { IDisplayConfig, IFormField } from '../../store/formBuilder.models';
import { IFieldResponse } from '../../../models/qr';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { CommonModule } from '@angular/common';
import { S3UrlPipe } from '../../../pipes/s3-url.pipe';

@Component({
  selector: 'app-contact-card',
  standalone: true,
  imports: [NzTypographyModule, NzAvatarModule, NzIconModule, NzDividerModule, NzButtonModule, S3UrlPipe, CommonModule],
  templateUrl: './contact-card.component.html',
  styleUrl: './contact-card.component.scss'
})
export class ContactCardComponent {

  @Input() form!: IFormField[]
  @Input() response!: Array<IFieldResponse & { key?: string }>
  @ViewChild('form', { static: false }) formElem: any;

  @Input() displayConfig: IDisplayConfig | null = null;

  listOrder = [
    {
      key: "Telephone No",
      icon: "phone",
    },
    {
      key: "Mobile No",
      icon: "mobile",
    },
    {
      key: "Email",
      icon: "mail",
    },
    {
      key: "Address",
      icon: "environment",
    },
    {
      key: "WebURL",
      icon: "global",
    },
  ]

  socialMedia = [
    {
      key: "XURL",
      img: "/assets/img/x.png"
    },
    {
      key: "FBURL",
      img: "/assets/img/fb.png"
    },
    {
      key: "LinkedInURL",
      img: "/assets/img/linkedin.png"
    }
  ]

  getFieldValueByKey(key: string) {
    return this.response.find(x => x.key === key)?.stringValue
  }

  getImagePath(key: string) {
    const res = this.response.find(x => x.key === key)?.fieldFiles
    return res ? res[0].filePath : ''
  }

  phonehref() {
    const phoneNo = this.getFieldValueByKey("Mobile No")
    return `tel:${phoneNo}`
  }

  mailtoRef() {
    const mailTo = this.getFieldValueByKey("Email")
    return `mailto: ${mailTo}`
  }

  downloadVCard() {
    const vCardContent = `BEGIN:VCARD
    VERSION:4.0
    N:${this.getFieldValueByKey('Name')}
    FN:${this.getFieldValueByKey('Name')}
    ORG:${this.getFieldValueByKey("Company Name")}
    TEL;TYPE=cell:${this.getFieldValueByKey("Telephone No")}
    TEL;TYPE=work:${this.getFieldValueByKey("Mobile No")}
    EMAIL:${this.getFieldValueByKey("Email")}
    END:VCARD`;

    const blob = new Blob([vCardContent], { type: 'text/vcard' });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', `${this.getFieldValueByKey('Name')}.vcf`);

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }

  ngAfterViewInit(): void {
    this.setStyles();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["displayConfig"]) {
      this.setStyles();
    }
  }

  setStyles() {
    if (!this.formElem) return;
    this.formElem.nativeElement.style.setProperty('--bg-color', this.displayConfig?.backgroundColor || '#fff');
    this.formElem.nativeElement.style.setProperty('--text-color', this.displayConfig?.textColor || '#434343');
    this.formElem.nativeElement.style.setProperty('--primary-color', this.displayConfig?.primaryColor || '#003a8c');
    this.formElem.nativeElement.style.setProperty('--label-color', this.displayConfig?.labelColor || '#595959');
    this.formElem.nativeElement.style.setProperty('--primary-font', this.displayConfig?.primaryFont ? `${this.displayConfig?.primaryFont}` : '"Archivo", sans-serif');
    this.formElem.nativeElement.style.setProperty('--display-font', this.displayConfig?.displayFont ? `${this.displayConfig?.displayFont}` : '"DM Serif Display", serif');

  }
}
