import { Injectable } from '@angular/core';
import { env } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, catchError, map, Observable, of, tap } from 'rxjs';
import { IDisplayConfig, IForm, IFormResponse } from '../store/formBuilder.models';
import { IAPIErrorResponse, IAPIResponse } from '../../../models/base';
import { IResponseAPIResponse, IResponsesRequest } from '../../models/qr';
import { IPublicGenericFormResponse } from '../../models/forms';
import { HttpService } from './http.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  private formsAPIUrl = `${env.apiBaseUrl}/forms-service/forms`;

  formSubject$ = new BehaviorSubject<IForm | null>(null);
  loading$ = new BehaviorSubject<boolean>(false);
  publicGenericFormSubject$ =
    new BehaviorSubject<IPublicGenericFormResponse | null>(null);
  errorList$ = new BehaviorSubject<{ [key: string]: string }>({});
  resetForm$ = new BehaviorSubject(false);

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private message: NzMessageService
  ) {}

  getForm(id: string): Observable<IFormResponse | IAPIErrorResponse> {
    const url = `${this.formsAPIUrl}/load/${id}`;
    return this.http
      .get<IFormResponse>(url)
      .pipe(catchError((error) => of(error.error as IAPIErrorResponse)));
  }

  getResponses(
    params: IResponsesRequest
  ): Observable<IResponseAPIResponse | IAPIErrorResponse> {
    const url = `${this.formsAPIUrl}/response/load`;
    return this.http.post<IResponseAPIResponse>(url, params);
  }

  generateQR(req: { formId: string; responseId: string }) {
    const url = `${this.formsAPIUrl}/response/qr/generate`;
    return this.http.post<IAPIResponse<{}>>(url, req);
  }

  deleteResponse(req: { formId: string; responseId: string }) {
    const url = `${this.formsAPIUrl}/response/delete`;
    return this.http.delete<IAPIResponse<{}>>(url, { body: req });
  }

  addResponse(data: FormData, formId: string) {
    const url = `${this.formsAPIUrl}/response/${formId}/submit`;
    return this.http.post<any>(url, data);
  }

  updateResponse(data: FormData, formId: string, responseId: string) {
    const url = `${this.formsAPIUrl}/response/${formId}/update/${responseId}`;
    return this.http.patch<any>(url, data);
  }

  updateDisplayConfig(formId: string, config: IDisplayConfig) {
    const url = `${this.formsAPIUrl}/config/updatedisplayconfig`;
    return this.http.put<IAPIResponse<{}>>(url, { ...config, formId });
  }

  getPublicGenericForm(id: string) {
    this.httpService.getGenericFormPublic(id).subscribe((form) => {
      this.publicGenericFormSubject$.next(form);
    });
  }

  resolvePublicGenericForm(id: string) {
    return this.httpService.getGenericFormPublic(id).pipe(
      tap((res) => {
        this.publicGenericFormSubject$.next(res);
      })
    );
  }

  resolveForm(id: string) {
    console.log(id)
    return this.httpService.getForm(id).pipe(
      map((form) => form.recordInfo),
      tap((res) => {
        this.formSubject$.next(res);
      })
    );
  }

  submitPublicGenericForm(formId: string, formData: FormData) {
    this.loading$.next(true);
    this.httpService
      .submitPublicGenericFormResponse(formId, formData)
      .subscribe({
        next: (res) => {
          this.loading$.next(false);
          this.message.create('success', 'Form Successfully Submitted', {
            nzDuration: 5000,
          });
          this.resetForm$.next(true);
        },
        error: (res) => {
          this.errorList$.next(res.error.errorlist);
          this.message.create('error', 'Form Submission failed', {
            nzDuration: 5000,
          });
          this.loading$.next(false);
        },
      });
  }
}
